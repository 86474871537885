
export default {
	name: "Home",
	layout: "website",
	data() {
		return {
			data: {},
			serverData: {
				cards: [
					"/images/banners/01.jpg",
					"/images/banners/02.jpg",
					"/images/banners/03.jpg",
				],
				mostOrdered: [],
			},
			mostPopular: [],
			newArrival: [],
		}
	},
	async fetch() {
		const promises = [
			this.$axios.$get(`v1/most-popular`).then((resp) => {
				this.mostPopular = resp
			}),
			this.$axios.$get(`v1/new-arrival`).then((resp) => {
				this.newArrival = resp
			}),
		]

		await Promise.all(promises)
	},

	computed: {
		categories() {
			return this.$store.state.categories
		},
	},
}
